import { h } from "@stencil/core";
export const ReadonlyIconElement = (props) => (h("dx-icon", { style: { "margin-left": "6px" }, icon: props.icon, size: 16 }));
export const BaselineElement = (props) => {
    var _a;
    if (props.onlyDiv) {
        return h("div", null);
    }
    const style = {
        overflow: "hidden",
        visibility: "hidden",
        width: "0",
        display: "inline-block",
    };
    if (props.fontSize) {
        style["--dx-text-font-size"] = props.fontSize + "px";
        style["--dx-text-line-height"] = "100%";
    }
    return (h("dx-text", { class: "baseline-text", style: style, type: (_a = props.type) !== null && _a !== void 0 ? _a : "auto" }, "b"));
};
